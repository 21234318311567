//Dependecies
import React from "react";

function Sidebar01({ property, bottom, copyLinkClick, isMobileOnly, clickWhatsapp }) 
{
  let message = 
  `_*CONSULTA*_

  %0aSaludos *${property.sales.biz}*,
  encontré la propiedad *${property.title}* en el enlace: *www.inmovisor.com/${property.slug}* 

  %0aAyúdeme con mas información
  `;

  return (
    <div

      style = {{ position: "fixed", bottom: bottom + 'vh', right: 10, zIndex : 4000}}
    >
        <button 
          className = "flex items-center justify-center text-white p-3 text-center mb-4 lg:mb-5 lg:w-20 lg:h-20 w-16 h-16 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
          onClick   = {() => copyLinkClick()}
          aria-label = "copy link"
        >
          <div
            className = "w-full h-full flex items-center justify-center rounded-2xl hover:bg-sky-600 text-gray-200 hover:text-gray-200 "                       
          >
            <svg 
              width = "1em" height = "1em" fill = "currentColor" 
              className = "flex text-3xl lg:text-4xl flex cursor-pointer" 
              xmlns = "http://www.w3.org/2000/svg" 
              viewBox = "0 0 640 512"
            >
              <path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"/>
            </svg>
          </div>
        </button>

        {property.sales.whatsapp && isMobileOnly &&
          <a 
            className = "flex items-center justify-center text-white p-3 text-center mb-2 md:mb-4 lg:mb-5 lg:w-20 lg:h-20 w-16 h-16 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
            target = '_blank'
            rel = "noopener noreferrer"
            aria-label = "whatsapp sales"

            href = {`whatsapp://send?phone=${property.sales.whatsapp}&text=${message}`}
            onClick = {() => clickWhatsapp()}
          >
            <div
              className = "w-full h-full flex items-center justify-center rounded-2xl hover:bg-sky-600 text-gray-200 hover:text-gray-200 "                       
            >
              <svg 
                width = "1em" height = "1em" fill = "currentColor" 
                className = "flex text-3xl lg:text-4xl flex cursor-pointer" 
                xmlns = "http://www.w3.org/2000/svg" 
                viewBox = "0 0 448 512"
              >
                <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
              </svg>
            </div>
          </a>
        }

        {property.sales.whatsapp && !isMobileOnly && 
          <a 
            className = "flex items-center justify-center text-white p-3 text-center mb-2 md:mb-4 lg:mb-5 lg:w-20 lg:h-20 w-16 h-16 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
            target = '_blank'
            rel = "noopener noreferrer"
            aria-label = "whatsapp sales"

            href = {`https://web.whatsapp.com/send?phone=${property.sales.whatsapp}&text=${message}`}
            onClick = {() => clickWhatsapp()}
          >
            <div
              className = "w-full h-full flex items-center justify-center rounded-2xl hover:bg-sky-600 text-gray-200 hover:text-gray-200 "                       
            >
              <svg 
                width = "1em" height = "1em" fill = "currentColor" 
                className = "flex text-3xl lg:text-4xl flex cursor-pointer" 
                xmlns = "http://www.w3.org/2000/svg" 
                viewBox = "0 0 448 512"
              >
                <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
              </svg>
            </div>
          </a>
        }
      
    </div>
  )
}


export default Sidebar01