//Components
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function SEO({ description, lang, meta, title, keywords, canonical, image, slug }) 
{ 
  const defaultTitle = title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}

      title = {title}
      titleTemplate = {defaultTitle ? `%s | Inmovisor` : null}

      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: `${title} | Inmovisor`,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:image:width`,
          content: "1000",
        },
        {
          property: `og:image:height`,
          content: "500",
        },
        {
          property: `og:image:type`,
          content: "image/jpg",
        },
        {
          property: `og:url`,
          content: `https://inmovisor.com/${slug}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "@entornoceo",
        },
        {
          name: `twitter:title`,
          content: `${title} | Inmovisor`,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: image,
        },
      ].concat(meta)}
    >
      <link rel="canonical" href={`https://${canonical}.com/${slug}`}/>      
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
