//Dependencies
import React from 'react';

function More01({ setVatiantID, variantID,  property, clicksNumber, setClicksNumber, bottom }) 
{
  const currentVariantID = variantID;
  const prevVariantID = currentVariantID - 1 === 0 ? Object.keys(property.variants).length : currentVariantID - 1;
  const nextVariantID = currentVariantID + 1 > Object.keys(property.variants).length ? 1 : currentVariantID + 1;

 
  return (
    <div
      className = "w-screen flex justify-around flex-col"

      style = {{ position: "fixed", bottom: bottom + 'vh', right: 10, zIndex : 1000}}
    >

      {clicksNumber === 0 && 
        <div
        className = 'flex justify-evenly items-center mx-auto mb-5 font-regular relative block rounded-lg text-black bg-green-500 p-4 lg:text-xl text-sm font-bold leading-5 text-white opacity-100 uppercase'
          role = "alert"
        >         
          Click para ver mas ambientes
        </div>
      }

      
      <div 
        className = 'flex justify-evenly items-center m-auto'
        style   =
        {{
          height: "100%",
          width: '300px'
        }}
      >

        <button 
          className = "flex items-center justify-center text-white p-3 text-center lg:w-20 lg:h-20 w-16 h-16 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
          onClick = {() => {setVatiantID(prevVariantID); setClicksNumber(clicksNumber + 1);}}
          aria-label = "Up property"
        >
          <div
            className = "w-full h-full flex items-center justify-center rounded-2xl hover:bg-sky-600 text-gray-200 hover:text-gray-200 "                       
          >
            <svg 
              width = "1em" height = "1em" fill = "currentColor" 
              className = "flex lg:text-5xl text-3xl flex cursor-pointer" 
              xmlns = "http://www.w3.org/2000/svg" 
              viewBox = "0 0 320 512"
            >
              <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"/>
            </svg>
          </div>
        </button>

        <button 
          className = "flex items-center justify-center text-white p-3 text-center lg:w-20 lg:h-20 w-16 h-16 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
          onClick = {() => {setVatiantID(nextVariantID); setClicksNumber(clicksNumber + 1); }}
          aria-label = "Up property"
        >
          <div
            className = "w-full h-full flex items-center justify-center rounded-2xl hover:bg-sky-600 text-gray-200 hover:text-gray-200 "                       
          >
            <svg 
              width = "1em" height = "1em" fill = "currentColor" 
              className = "flex text-xl lg:text-5xl text-3xl flex cursor-pointer" 
              xmlns = "http://www.w3.org/2000/svg" 
              viewBox = "0 0 320 512"
            >
              <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/>
            </svg>
          </div>
        </button>

      </div>     
    </div>
  )
}


export default More01