//Dependecies
import React from "react";

function Title01({ property }) 
{
  return (
    <div
      className = "fixed absolute justify-center flex items-center bg-white p-0 w-screen h-[30px] z-10 bg-opacity-75"
    >
      <div className = "flex">
        <h2
          className = "md:text-2xl text-base font-bold uppercase"
        >
          {property.title}
        </h2>
      </div>
     
    </div>
  )
}


export default Title01