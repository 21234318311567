//Dependencies
import React from "react"
import PropTypes from "prop-types"
//Components
import Title from "../../components/properties/title";
import Price from "../../components/properties/price";
import Data from "../../components/properties/data";
import Sidebar from "../../components/properties/sidebar"; 
import More from "../../components/properties/more";
//import Footer from "../../components/properties/footer";

const Layout = ({ children, property, isMobileOnly, variantID, setVatiantID, clicksNumber, setClicksNumber, menuHeight, copyLinkClick, clickWhatsapp, handleScroll }) => 
{
  return (
    <div
      className = "select-none"
      onWheel = {(e) => handleScroll(e)}
    >
      <Title
        property = {property}
      />

      <Price
        property = {property}
      />

      <Data
        property = {property}
      />

      <main>
        {children}
      </main>


      <Sidebar
        property = {property}
        isMobileOnly = {isMobileOnly}

        bottom = {10}

        copyLinkClick = {copyLinkClick}
        clickWhatsapp = {clickWhatsapp}
      />

      <More
        property = {property}

        setVatiantID = {setVatiantID}
        variantID = {variantID}

        clicksNumber = {clicksNumber}
        setClicksNumber = {setClicksNumber}

        bottom = {menuHeight}
      />      

      {/*
        <Footer
          menuHeight = {menuHeight}
        />
      */}
    </div>
  )
}

Layout.propTypes = 
{
  children: PropTypes.node.isRequired,
}

export default Layout