//Dependecies
import React from "react";

function Price01({ property }) 
{
  return (
    <div
      className = "fixed absolute justify-center flex items-center bg-white p-0 w-screen h-[30px] z-10 top-[30px] bg-opacity-75 border-b border-solid border-black"
    >
      <div className = "flex">
        {property.data.transaction === 'rent' &&
          <h2
            className = "md:text-2xl text-base font-bold uppercase"
          >
            ALQUILER
          </h2>
        }

        {property.data.transaction === 'sale' &&
          <h2
            className = "md:text-2xl text-base font-bold uppercase"
          >
            VENTA
          </h2>
        }        

        &nbsp;

        <span
          className = "md:text-2xl text-base font-bold uppercase"
        >
          {` - ${property.data.price.toFixed(0)} $ ${property.data.transaction === 'rent' ? "/mes" : ""}`}
        </span>     


      </div>
     
    </div>
  )
}


export default Price01