//Dependencies
import React, { useEffect, useState } from 'react';
import { graphql } from "gatsby";
import { isMobileOnly, browserName } from 'react-device-detect';
import { getImage } from 'gatsby-plugin-image';
import { useSwipeable } from "react-swipeable";
//Components
import Seo from "../components/properties/seo";
import Layout from '../components/properties/layout';
import BackgroundImage from "../components/properties/bg";

const { TelegramClient } = require('messaging-api-telegram');
const userID = '1196253376';
const client = new TelegramClient({
  accessToken: '2099891144:AAGVK_5nJ3nUX0mwb-eUMFc-gWlJAsPuYIA',
  onRequest: ({ method, url, headers, body }) => 
  {
    /* */
  },
});

const PropertyDetails = ({ data }) => 
{
  const [variantID, setVatiantID] = useState(1);
  const [clicksNumber, setClicksNumber] = useState(0);
  const [deviceDetected, setDeviceDetected] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [scrollingCount, setScrollingCount] = useState(1);  

  /* DEVICE DETECT */
  useEffect(() => 
  {
    if (isMobileOnly) 
    {
      setDeviceDetected(true);
    }
  }, []);

  const property = data.property;

  const slide = (dir) => 
  {
    const currentVariantID = variantID;
    const prevVariantID = currentVariantID - 1 === 0 ? Object.keys(property.variants).length : currentVariantID - 1;
    const nextVariantID = currentVariantID + 1 > Object.keys(property.variants).length ? 1 : currentVariantID + 1;
    
    if(dir === 'up')
    {
      setVatiantID(nextVariantID); setClicksNumber(clicksNumber + 1);
    }

    if(dir === 'down')
    {      
      setVatiantID(prevVariantID); setClicksNumber(clicksNumber + 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedUp: () => slide('up'),
    onSwipedDown: () => slide('down'),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  /* HANDLE WHEN SCROLL */
  const handleScroll = (e) => 
  {
    handleWheelInsideDiv();

    if(scrollingCount > 3)
    {
      if(e.deltaY > 0)
      {
        slide('up')
      }
      else
      {
        slide('down')
      }

      setScrollingCount(0)
    }
    else
    {
      setScrollingCount(scrollingCount + 1)
    }
  };

  const handleWheelInsideDiv = () => 
  {
    setScrolling(true);
    
    const scrollTimeout = setTimeout(() => {
      setScrolling(false);
    }, 500); // Define un período de inactividad de 100 ms antes de considerar que la rueda se ha detenido

    clearTimeout(scrollTimeout);    
  };

  useEffect(() => {
    if (!scrolling) {
      setScrollingCount(1)
    }
  }, [scrolling]);

  /* ON LOAD */
  useEffect(() => 
  {
    let message = `actividad: ingreso\npropiedad: ${property.meta.title}\nempresa: ${property.sales.biz}\nurl: www.inmovisor.com/${property.slug}\nmobile: ${isMobileOnly}\nbrowser: ${browserName}`;

    try 
    { 
      client.getWebhookInfo().catch((error) => 
      {
        console.log(error); // formatted error message
        console.log(error.stack); // error stack trace
        console.log(error.config); // axios request config
        console.log(error.request); // HTTP request
        console.log(error.response); // HTTP response
      }); 

      client.sendMessage(userID, message).then(() => 
      {
        console.log('sent');
      }); 
    } 
    catch (error) 
    {
      console.log(error);
    }

  }, [property]);

  /* COPIAR ENLACE */
  const copyLinkClick = () =>
  {
    if (typeof window !== 'undefined') 
    {
      let enlace = window.location.href;
      navigator.clipboard.writeText(enlace);
      
      alert("Enlace Copiado!")

      let message = `actividad: copiar enlace\npropiedad: ${property.meta.title}\nempresa: ${property.sales.biz}\nurl: www.inmovisor.com/${property.slug}\nmobile: ${isMobileOnly}\nbrowser: ${browserName}`;

      try 
      { 
        client.getWebhookInfo().catch((error) => 
        {
          console.log(error); // formatted error message
          console.log(error.stack); // error stack trace
          console.log(error.config); // axios request config
          console.log(error.request); // HTTP request
          console.log(error.response); // HTTP response
        }); 
  
        client.sendMessage(userID, message).then(() => 
        {
          console.log('sent');
        }); 
      } 
      catch (error) 
      {
        console.log(error);
      }
    }
  };

  /* ABRIÓ WHATSAPP */
  const clickWhatsapp = () =>
  {
    let message = `actividad: click whatsapp\npropiedad: ${property.meta.title}\nempresa: ${property.sales.biz}\nurl: www.inmovisor.com/${property.slug}\nmobile: ${isMobileOnly}\nbrowser: ${browserName}`;

    try 
    { 
      client.getWebhookInfo().catch((error) => 
      {
        console.log(error); // formatted error message
        console.log(error.stack); // error stack trace
        console.log(error.config); // axios request config
        console.log(error.request); // HTTP request
        console.log(error.response); // HTTP response
      }); 

      client.sendMessage(userID, message).then(() => 
      {
        console.log('sent');
      }); 
    } 
    catch (error) 
    {
      console.log(error);
    }
  };

  /* NUMBER OF CLICKS TO SEE ALL AREAS */
  useEffect(() => 
  {
    if(clicksNumber === 1)
    {
      let message = `actividad: ver resto de ambientes\npropiedad: ${property.meta.title}\nempresa: ${property.sales.biz}\nurl: www.inmovisor.com/${property.slug}\nmobile: ${isMobileOnly}\nbrowser: ${browserName}`;

      try 
      { 
        client.getWebhookInfo().catch((error) => 
        {
          console.log(error); // formatted error message
          console.log(error.stack); // error stack trace
          console.log(error.config); // axios request config
          console.log(error.request); // HTTP request
          console.log(error.response); // HTTP response
        }); 

        client.sendMessage(userID, message).then(() => 
        {
          console.log('sent');
        }); 
      } 
      catch (error) 
      {
        console.log(error);
      }
    }

  }, [clicksNumber, property]);  

  return(
    <Layout
      property = {data.property}
      isMobileOnly = {deviceDetected}

      setVatiantID = {setVatiantID}
      variantID = {variantID}

      clicksNumber = {clicksNumber}
      setClicksNumber = {setClicksNumber}

      menuHeight = {2}

      copyLinkClick = {copyLinkClick}
      clickWhatsapp = {clickWhatsapp}

      handleScroll = {handleScroll}
    >
      <Seo
        title = {property.meta.title}
        slug   = {property.slug}
        description = {property.meta.description}
        keywords  = {property.meta.keywords}

        canonical = {'inmovisor'}

        image = {`https://inmovisor.com${getImage(property.meta.image.childrenImageSharp[0]).images.fallback.src}`}
        //image = {`http://localhost:8000${getImage(property.meta.image.childrenImageSharp[0]).images.fallback.src}`}
      />

      <div      
        //style = {{ minHeight: '-webkit-fill-available' , height : (100) + 'vh', width : '100%', paddingTop : 0, paddingBottom : 0 }}

        style = 
        {{
          height : '100vh',
          width : '100vw',
        }}
      >

        <BackgroundImage
          variants = {property.variants}
          variantID = {variantID}  
          
          isMobileOnly = {deviceDetected}

          handlers = {handlers}
        />

      </div>   

    </Layout>
    
  )
}

export default PropertyDetails

//Properies Query
export const query = graphql`
query PropertyQuery($slug: String) {
  properties: allPropertiesJson(filter: {visible: {eq: true}}) {
    nodes {
      property
      visible
      slug
      title
    }
  }
  property: propertiesJson(slug: {eq: $slug}) {
    property
    visible
    slug
    title
    data {
      rooms
      baths
      parking
      area
      age
      transaction
      price
      category
    }
    sales {
      whatsapp
      biz
      bizSlug
    }
    meta {
      title
      description
      keywords
      image {
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
    variants {
      id
      alt
      horizontal {
        childrenImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
            webpOptions: {quality: 95}
            jpgOptions: {progressive: true, quality: 90}
            blurredOptions: {width: 10}
            formats: WEBP
            width: 1920
            breakpoints: [320, 480, 640, 1024, 1920]
          )
        }
      }
      vertical {
        childrenImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
            webpOptions: {quality: 75}
            jpgOptions: {progressive: true, quality: 70}
            blurredOptions: {width: 10}
            formats: WEBP
            width: 640
            breakpoints: [320, 480, 640, 1024, 1920]
          )
        }
      }
    }
  }
}
`