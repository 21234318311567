import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Background = ({variants, variantID, isMobileOnly, handlers}) => 
{  
  let product = variants.find(f1 => f1.id === variantID);
  let image = getImage(product[isMobileOnly === true ? 'vertical' : 'horizontal'].childrenImageSharp[0]);   

  return (
    <div
      {...handlers}
      className = "h-full w-full absolute"    
      
      style = 
      {{
        height : '100vh',
        width : '100vw',
      }}
    >
      <GatsbyImage
        image = {image}
        alt = 'prueba'       

        className = "h-full w-full object-cover absolute inset-0"
        //className = 'fixed absolute flex justify-center items-center flex-col bg-gray-900 bg-opacity-80 select-none'
      />      
    </div>
  )
}



export default Background